<template>
  <div>
    <el-card>
      <el-steps :active="setupActive" align-center finish-status="success" class="mb20">
        <el-step :title="$t('text.basicSettings')"></el-step>
        <el-step :title="$t('text.productManagement')"></el-step>
      </el-steps>
      <alter-form labelWidth="210px" :customeButton="true" @handleChange="handleChange" ref="alterForm" @formSubmit="formSubmit" :modifyData="modifyData" :formData="formData" :collapseLength="collapseLength">
        <template v-slot:customeButton="data">
          <el-button @click="handleCancelClick">{{ $t('button.cancel') }}</el-button>
          <el-button type="primary" @click="formSubmit(data)">{{ btnTxt }}</el-button>
        </template>
      </alter-form>
    </el-card>
  </div>
</template>

<script>
import onlineVoucherAdd from './index.js'
export default onlineVoucherAdd
</script>

<style lang="scss" scope>
@import './index.scss';
</style>
