// 组件
import importImg from '@/components/importImg.vue'
import alterForm from '@/components/alterForm'
// 接口
import { getOnlineVoucherEventConfigurations, getShopList, getShopProduct, onlineVoucherEventAdd, getSimplelist, getSiteSimpList, getTicketPackageSimpList, getTicketTypeSimpList } from '@/services/coupon.js'
// 其他
import loading from '@/utils/loading.json'
import jsonData from './jsonData'
import constant from '@/utils/constant.js'

export default {
  name: 'onlineVoucherEdit',
  components: {
    importImg,
    alterForm
  },
  data () {
    return {
      modifyData: {},
      collapseLength: [],
      formData: jsonData.formData,
      btnTxt: this.$t('button.confirm'),
      isNext: false,
      setupActive: 0,
      applyToOptions: [
        {
          label: this.$t('form.coupon.entireShop'),
          value: 'entire_shop'
        },
        {
          label: this.$t('form.coupon.selectedProducts'),
          value: 'selected_products'
        }
      ],
      ticketApplyToOptions: [
        {
          label: this.$t('form.coupon.entireTicket'),
          value: 'entire_tickets'
        },
        {
          label: this.$t('form.coupon.selectedTicket'),
          value: 'selected_tickets'
        }
      ],
      site_id: ''
    }
  },
  watch: {
  },
  created () {
    this.init()
  },
  mounted () {
  },
  methods: {
    init () {
      this.collapseLength = [...new Array(this.formData.length).keys()]
      this.$store.state.app.loading = this.$loading(loading)
      getOnlineVoucherEventConfigurations().then(res => {
        if (res.status === 200) {
          this.formData[0].createDateItem[7].options = res.data.voucher_types
          this.formData[0].createDateItem[15].options = res.data.applied_target_types
          this.formData[0].createDateItem[12].options = res.data.code_generations
          this.formData[0].createDateItem[19].options = this.applyToOptions
          this.formData[0].createDateItem[8].options = res.data.base_prices
          console.log(this.formData)
        }
      }).finally(() => {
        this.$store.state.app.loading.close()
      })
      getShopList().then(res => {
        if (res.status === 200) {
          this.formData[0].createDateItem[16].options = res.data.shops
          this.formData[0].createDateItem[18].options = res.data.shops
        }
      })
    },
    handleChange (data, prop, data1, cData) {
      console.log(data, prop)
      console.log('-------data1--------', data1)
      console.log('-------cData--------', cData)

      if (prop === 'applied_target_type') {
        if (data === 'coupon') {
          this.getOfflineVoucherList()
        }
        if (data === 'ticket_package') {
          this.getSites()
        }
        if (data === 'ticket_type') {
          this.getTicketType()
        }
      }
      if (prop === 'ticket_apply_to') {
        if (data === 'selected_tickets') {
          this.getTicketPackage()
        }
        // if (data === 'selected_tickets' && cData.applied_target_type === 'ticket_type') {
        //   this.getTicketType()
        // }
      }
      if (prop === 'site_id') {
        this.site_id = data
      }
      if (prop === 'apply_to') {
        if (data === 'selected_products') {
          this.btnTxt = this.$t('button.next')
          this.isNext = true
        } else {
          this.btnTxt = this.$t('button.confirm')
          this.isNext = false
        }
      }
      if (prop === 'apply_to') {
        // this.getProduct(data)
      }
    },
    getProduct (shop_id) {
      const params = {
        shop_id,
        all: true,
        only_name: true
      }
      getShopProduct(params).then(res => {
        res.data.shop_items.unshift({ id: 0, name: this.$t('form.coupon.all') })
        this.formData[0].createDateItem[12].options = res.data.shop_items
      })
    },
    getOfflineVoucherList () {
      getSimplelist().then(res => {
        res.data.offline_coupons.unshift({ id: 0, name: this.$t('form.coupon.all') })
        this.formData[0].createDateItem[17].options = res.data.offline_coupons
      })
    },
    formSubmit (data) {
      this.$store.state.app.loading = this.$loading(loading)
      const obj = JSON.parse(JSON.stringify(data.data))
      if (obj.applied_target_type === 'product') {
        if (!obj.shop_id) {
          this.$store.state.app.loading.close()
          this.$message.error(this.$t('text.require', { name: this.$t('form.coupon.chooseShop') }))
          return
        }
        if (!obj.apply_to) {
          this.$store.state.app.loading.close()
          this.$message.error(this.$t('text.require', { name: this.$t('form.coupon.applyTo') }))
          return
        }
        obj.shop_ids = [obj.shop_id]
      }
      // if (obj.applied_target_type === 'ticket_type') {
      //   obj.ticket_type_ids = obj.ticket_package_ids
      //   delete obj.ticket_package_ids
      // }
      if (obj.apply_to === 'entire_shop') {
        obj.shop_item_ids = [0]
      }
      if (obj.ticket_apply_to === 'entire_tickets') {
        obj.ticket_package_ids = [0]
        // if (obj.applied_target_type === 'ticket_type') {
        //   obj.ticket_type_ids = obj.ticket_package_ids
        //   delete obj.ticket_package_ids
        // }
      }
      delete obj.apply_to
      delete obj.shop_id
      delete obj.ticket_apply_to

      const params = {
        online_voucher_code_event: obj
      }
      onlineVoucherEventAdd(params, this.$route.params.id).then(res => {
        if (res.status === 200) {
          this.$message({
            type: 'success',
            message: this.$t('message.operateSuccess')
          })
          if (this.btnTxt === this.$t('button.confirm')) {
            setTimeout(() => {
              this.$router.push({
                name: 'onlineVoucherCodeList'
              })
            }, constant.ROUTER_CHANGE_INTERVAL)
          } else {
            this.setupActive = 1
            this.$router.push({
              name: 'onlineVoucherCodeProductList',
              query: {
                id: res.data.online_voucher_code_event.id,
                shop_id_eq: data.data.shop_id
              }
            })
          }
        }
      }).finally(() => {
        this.$store.state.app.loading.close()
      })
    },
    handleCancelClick () {
      this.$router.back(-1)
    },
    getSites () {
      getSiteSimpList().then(res => {
        if (res.status === 200) {
          this.formData[0].createDateItem[20].options = res.data.sites
          this.formData[0].createDateItem[21].options = this.ticketApplyToOptions
        }
      })
    },
    getTicketPackage () {
      const params = {
        site_id: this.site_id
      }
      getTicketPackageSimpList(params).then(res => {
        if (res.status === 200) {
          console.log(res.data.ticket_packages)
          this.formData[0].createDateItem[22].options = res.data.ticket_packages
        }
      })
    },
    getTicketType () {
      // const params = {
      //   site_id: this.site_id
      // }
      getTicketTypeSimpList().then(res => {
        if (res.status === 200) {
          this.formData[0].createDateItem[23].options = res.data.ticket_types
        }
      })
    }
  }
}
